.gridContainer {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
    grid-column-gap: 10px;
    justify-items: center;
}
.gridItem {
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-bottom: 20px;
    justify-items: center;
}